import React from 'react'
import { plansData } from '../../data/plansData'
import "./Plans.css"
import {images} from "../../constants"
import { AppWrap} from '../../wrapper';


const Plans = () => {
  return (
    <div className='plans__app'>
      <duv className="blur plans-blur-1"></duv>
      <duv className="blur plans-blur-2"></duv>
      {/*naslov----------------- */}
      <div className="plans__header">
        <span className='stroke-text'>READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className='stroke-text'>NOW WITHUS</span>      
      </div>
      {/*plans card----------------- */}
      <div className='plans'>
        {plansData.map((plan,i)=>(
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>€ {plan.price}</span>

            <div className='features'>
              {plan.features.map((feature, i )=>(
                <div className='feature'>
                  <img src={images.whiteTick} alt=""/>
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>
            {/*-------------------*/}
            <div>
                <span>See more benefits -></span>
            </div>
            <button className='btn'>Join Now</button>
          </div>
        ))} 
      </div>
    </div>
  )
}

export default AppWrap(Plans, "plans")