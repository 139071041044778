import React from "react";
import "./Iskopi.css";
import { AppWrap } from '../../wrapper';

import bager1 from './IskopiSlike/bager1.jpg';
import bager2 from './IskopiSlike/bager2.jpg';
import bager3 from './IskopiSlike/bager3.jpg';
import bager4 from './IskopiSlike/bager4.jpg';
import bager5 from './IskopiSlike/bager5.jpg';
import bager6 from './IskopiSlike/bager6.jpg';



const Iskopi = () => {
  return (
    <div id="iskopi_galerija" className="iskopi-container">
      <div className="iskopi-header">
        <h2>Iskopi Bagerom do 6 Tona</h2>
        <p>
          Naša stručnost u iskopima obuhvaća širok spektar usluga, od iskopavanja temelja, rušenja objekata, čišćenja terena, do iskopavanja kanala za vodovod i odvodnju. Koristimo bagerima do 6 tona kako bismo osigurali preciznost i sigurnost pri svakom zadatku.
        </p>
      </div>
      <div className="iskopi-gallery">
        <img src={bager1} alt="Iskop 1" className="iskopi-image" />
        <img src={bager2} alt="Iskop 2" className="iskopi-image" />
        <img src={bager3} alt="Iskop 3" className="iskopi-image" />
        <img src={bager4} alt="Iskop 4" className="iskopi-image" />
        <img src={bager5} alt="Iskop 5" className="iskopi-image" />
        <img src={bager6} alt="Iskop 6" className="iskopi-image" />

      </div>
      <div className="iskopi-info">
        <h3>Zašto odabrati nas?</h3>
        <ul>
          <li>Brzi i učinkoviti radovi s bagerima do 6 tona</li>
          <li>Iskopi za temelje, kanalizaciju, vodovod i drenaže</li>
          <li>Čišćenje terena i priprema za gradilišta</li>
          <li>Stručnjaci sa dugogodišnjim iskustvom</li>
          <li>Sigurnost i preciznost pri svakom zadatku</li>
        </ul>
      </div>
    </div>
  );
};

export default AppWrap(Iskopi, 'iskopi');

