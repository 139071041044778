import React, { useState, useCallback } from "react";
import './Vizuali.css';
import { AppWrap } from '../../wrapper';

import Slika1Prije from './Vizuali/pantan1.jpg';
import Slika1Poslije from './Vizuali/pantan2.jpg';
import Slika2Prije from './Vizuali/industriska1.jpg';
import Slika2Poslije from './Vizuali/industriska2.jpg';

import Slika3Prije from './Vizuali/KastelaPlodine1A.jpg';
import Slika3Poslije from './Vizuali/KastelaPlodine1B.jpg';
import Slika4Prije from './Vizuali/KastelaPlodine2A.jpg';
import Slika4Poslije from './Vizuali/KastelaPlodine2B.jpg';

const Slider = ({ beforeImage, afterImage }) => {
  const [position, setPosition] = useState(100); // Početna pozicija klizača

  const handleSlide = (event) => {
    const newPosition = event.target.value;
    setPosition(newPosition);
  };

  return (
    <div id="Vizuali" className="Slider">
      <div className="Slider__images">
        {/* Prva slika (Before) */}
        <img src={beforeImage} alt="Before" style={{ width: `${100 - position}%` }} />
        
        {/* Druga slika (After) */}
        <img src={afterImage} alt="After" style={{ width: `${position}%` }} />
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value={position}
        onChange={handleSlide}
        className="Slider__input"
      />

    </div>
  );
};



const Vizuali = () => {
  return (
    <div className='Advertising' id='advertising'>
      <div className='Offers__header'>
        <span className='stroke-text'>Vizuali</span>
        <span>Print  &  Promjena</span>
      </div>
      <div className="Poruka">
        Dobrodošli u 22Red d.o.o., vašeg partnera za svježi vizual i vrhunski ispis cerada na billboardima. Transformiramo prostore oglašavanja brzo i kvalitetno. Kontaktirajte nas danas!
      </div>
      <div className='Advertising__cards'>
        <div className='Advertising__card'>
          <Slider beforeImage={Slika1Prije} afterImage={Slika1Poslije} />
          <div className='Advertising__card-content'>
            <h3>Primjer 1.</h3>
            <p>Brza, precizna i kvalitetna izmjena vizuala – garantiramo da vaš prostor bude uvijek u centru pažnje!"</p>
          </div>
        </div>

        <div className='Advertising__card'>
          <Slider beforeImage={Slika2Prije} afterImage={Slika2Poslije} />
          <div className='Advertising__card-content'>
            <h3>Primjer 2.</h3>
            <p>Pružamo najmodernije rješenja za vašu reklamu – od ideje do izvedbe, naš rad je ključ uspjeha!</p>
          </div>
        </div>

        <div className='Advertising__card'>
          <Slider beforeImage={Slika3Prije} afterImage={Slika3Poslije} />
          <div className='Advertising__card-content'>
            <h3>Primjer 3.</h3>
            <p>S profesionalnim pristupom i vrhunskim ispisom, svaki vizual pričat će priču koja ostavlja dojam.</p>
          </div>
        </div>

        <div className='Advertising__card'>
          <Slider beforeImage={Slika4Prije} afterImage={Slika4Poslije} />
          <div className='Advertising__card-content'>
            <h3>Primjer 4.</h3>
            <p>transformiranje prostora u prave vizualne remek-djela – svaki oglas zaslužuje našu pažnju i preciznost!</p>
          </div>
        </div>
        {/* Dodajte još kartica za ostale slike ako je potrebno */}
      </div>
    </div>
  );
};

export default AppWrap(Vizuali, 'promjena-vizuala');
