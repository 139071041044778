import React, { useState, useEffect } from 'react';
import './Header.css';
import { images } from '../../constants';

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Glavni linkovi
  const mainLinks = [
    'Početna',
    'vanjsko Oglašavanje',
    'Iskopi',
    'IT Solutions',
    'Kontakt',
  ];

  // Podlinkovi povezani sa svakim glavnim linkom
  const subLinks = {
    'vanjsko Oglašavanje': [
      { label: 'Lokacije', href: '#Oglasavanje' },
      { label: 'Promjena Vizuala', href: '#promjena-vizuala' },
      { label: 'Partneri', href: '#Partneri' },
      { label: 'Iskustva Korisnika', href: '#testimonials' },
    ],
    'IT Solutions': [
      { label: 'IT Solutions', href: '#itsolutions' },
      { label: 'Prodaja i Ugradnja', href: '#itprodaja' },
      { label: 'Radovi', href: '#' },
    ],
    'Iskopi': [
      { label: 'Iskopi Galerija', href: '#iskopi_galerija' },
    ]
  };

  const handleLinkClick = (event) => {
    const link = event.currentTarget;
    link.classList.toggle('active');
  };

  return (
    <div className="header">
      {menuOpened === false && mobile ? (
        <div
          style={{
            backgroundColor: 'var(--appColor)',
            padding: '0.5rem',
            borderRadius: '5px',
          }}
        >
          <img
            src={images.bars}
            alt="menu icon"
            style={{ width: '1.5rem', height: '1.5rem' }}
            onClick={() => setMenuOpened(true)}
          />
        </div>
      ) : (
        <ul className="header__links">
          {mainLinks.map((item) => (
            <li
              className={`links ${mobile && item === 'vanjsko Oglašavanje' ? 'active' : ''}`}
              key={`link-${item}`}
              onClick={(event) => {
                setMenuOpened(false);
                if (mobile && item === 'vanjsko Oglašavanje') {
                  handleLinkClick(event);
                }
              }}
            >
                          <li
              className={`links ${mobile && item === 'IT Solutions' ? 'active' : ''}`}
              key={`link-${item}`}
              onClick={(event) => {
                setMenuOpened(false);
                if (mobile && item === 'IT Solutions') {
                  handleLinkClick(event);
                }
              }}
            ></li>
              <div />
              <a href={`#${item.toLowerCase().replace(' ', '-')}`}>{item}</a>
              {/* Prikazivanje odgovarajućih podlinkova za svaki glavni link */}
              {subLinks[item] && (
                <ul className="dropdown">
                  {subLinks[item].map((subItem) => (
                    <li key={subItem.href}>
                      <a href={subItem.href}>{subItem.label}</a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
      <img src={images.redLogo} alt="logo" className="logo" />
    </div>
  );
};

export default Header;