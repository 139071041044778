import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";
import image5 from "../assets/t-image4.jpg";
import defImage from "../assets/defImage.jpg";

export const testimonialsData = [
  {
    image: defImage,
    review:
      "Suradnja s tvrtkom 22Red bila je izvrsna. Promjena vizuala koju su izvršili za naš brend nije samo poboljšala vizualni dojam, već je značajno povećala prepoznatljivost i angažman naših klijenata. Njihov tim je precizan, brz i uvijek usmjeren na naše ciljeve. Preporučili bismo ih svakom poslovnom partneru koji traži kreativnost i kvalitetu.",
    name: 'Mario V.',
    status : 'Marketing Manager'
  },
  {
    image: defImage,
    review: "Nakon što je 22Red promijenio vizualnu komunikaciju našeg restorana, primijetili smo izuzetan porast broja novih gostiju. Vizuali koje su postavili na fasadi bili su moderni, jasni i atraktivni. Tim je bio izuzetno profesionalan, a cijeli proces je prošao glatko i bez problema. Definitivno ćemo nastaviti suradnju!",
    name: 'Ante B.',
    status: 'Vlasnik restorana '
  },
  {
    image : defImage,
    review:"Kada smo odlučili unaprijediti našu reklamu na billboardima u Dalmaciji, odabrali smo 22Red i nismo pogriješili. Promjena vizuala bila je savršena – plijenio je pažnju vozača i turista, a reakcije su bile izvanredne. Sada imamo mnogo više upita i rezervacija. Profesionalnost tima i kreativnost u pristupu učinili su cijeli proces jednostavnim i brzim. Preporučujemo ih svakome tko želi postići vidljivost na tržištu!",
    name: 'Petra L.',
    status: "Direktorica turističke agencije"
  },
  {
    image : defImage,
    review:"Suradnja s 22Red na promjeni vizuala na našem billboardu bila je prava investicija. Njihov tim je odlično razumio naše potrebe i stvorio dizajn koji odražava duh našeg restorana. Billboard sada ne samo da privlači pažnju, nego i odražava našu brand filozofiju, što je pozitivno utjecalo na broj posjeta. Brza reakcija i visoka kvaliteta rada bili su ključni za nas. Preporučujemo ih svim poslovima koji žele biti primijećeni!",
    name: 'Jure B.',
    status: "Organizator"
  },
  {
    image : defImage,
    review:"Oglašavanje na billboardima tvrtke 22Red bila je izvrsna odluka za naš brend. Pano koji se nalazi u samom srcu Splita donio nam je nevjerojatnu vidljivost među lokalnim i turističkim kupcima. Od kad smo postavili naš oglas, zabilježili smo porast prodaje, a čak su nam se počeli javljati i novi poslovni partneri. Tim 22Red-a nam je pomogao u kreiranju vizuala koji su privukli pažnju na pravi način. Preporučujemo svakome tko želi iskoristiti snagu vanjskog oglašavanja!",
    name: 'Maja K.',
    status: "Trgovac"
  },
  {
    image : defImage,
    review:"Kao agencija koja organizira velike događaje, oglašavanje na billboardima 22Red nam je omogućilo nevjerojatnu izloženost. Naši eventi sada privlače puno veću publiku, a vizuali na billboardima su profesionalno dizajnirani i savršeno usklađeni s našim brendom. Odlično iskustvo s timom 22Red, brza i efikasna usluga. Svakako ćemo nastaviti suradnju!",
    name: 'Tomislav R.',
    status: " Direktor agencije"
  },
];