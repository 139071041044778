import React from 'react';
import './ITProdaja.css'; // Import za stilove
import hd1 from './ITSlike/hd1.jpg';
import hd2 from './ITSlike/hd2.jpg';
import hd3 from './ITSlike/hd3.jpg';
import hd4 from './ITSlike/hd4.jpg';
import hd5 from './ITSlike/hd5.jpg';
import hd6 from './ITSlike/hd6.jpg';
import { AppWrap } from '../../wrapper';

const ITProdaja = () => {
  return (
    <div id="itprodaja">
      {/* Header sekcija */}
      <section className="product-section">
        <div className='Offers__header'>
        <span className='stroke-text'>prodaja</span>
        <span>ugradnja</span>
        <span className='stroke-text'>servis</span>
      </div>

        {/* Proizvodi u galeriji */}
        <div className="product-grid">
          <div className="product-item">
            <img src={hd1} alt="Rad 1" className="product-item__image" />
            <h4 className="product-item__name">Proizvod 1</h4>
            <p className="product-item__price">Cijena: </p>
            <p className="product-item__description">Opis proizvoda 1 - Kratki opis o proizvodu i usluzi.</p>
          </div>

          <div className="product-item">
            <img src={hd2} alt="Rad 2" className="product-item__image" />
            <h4 className="product-item__name">Proizvod 2</h4>
            <p className="product-item__price">Cijena: </p>
            <p className="product-item__description">Opis proizvoda 2 - Kratki opis o proizvodu i usluzi.</p>
          </div>

          <div className="product-item">
            <img src={hd3} alt="Rad 3" className="product-item__image" />
            <h4 className="product-item__name">Proizvod 3</h4>
            <p className="product-item__price">Cijena: </p>
            <p className="product-item__description">Opis proizvoda 3 - Kratki opis o proizvodu i usluzi.</p>
          </div>

          <div className="product-item">
            <img src={hd4} alt="Rad 4" className="product-item__image" />
            <h4 className="product-item__name">Proizvod 4</h4>
            <p className="product-item__price">Cijena: </p>
            <p className="product-item__description">Opis proizvoda 4 - Kratki opis o proizvodu i usluzi.</p>
          </div>

          <div className="product-item">
            <img src={hd5} alt="Rad 5" className="product-item__image" />
            <h4 className="product-item__name">Proizvod 5</h4>
            <p className="product-item__price">Cijena: </p>
            <p className="product-item__description">Opis proizvoda 5 - Kratki opis o proizvodu i usluzi.</p>
          </div>

          <div className="product-item">
            <img src={hd6} alt="Rad 6" className="product-item__image" />
            <h4 className="product-item__name">Proizvod 6</h4>
            <p className="product-item__price">Cijena: </p>
            <p className="product-item__description">Opis proizvoda 6 - Kratki opis o proizvodu i usluzi.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AppWrap(ITProdaja, 'itprodaja');
