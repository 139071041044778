import React from 'react';
import './ITSolutions.css'; // Import za stilove
import yourImage from './ITSlike/radnaSoba.jpg'; // Import slike iz istog foldera
import hd1 from './ITSlike/hd1.jpg';
import hd2 from './ITSlike/hd2.jpg';
import hd3 from './ITSlike/hd3.jpg';
import hd4 from './ITSlike/hd4.jpg';
import hd5 from './ITSlike/hd5.jpg';
import hd6 from './ITSlike/hd6.jpg';

import { AppWrap} from '../../wrapper';


const ITSolutions = () => {
  return (
    <div id="itsolutions">
      {/* Header sekcija */}
      <div className="it__header">
        <span className="stroke-text">IT</span>
        <span>SOLUTIONS</span>
      </div>

      {/* Glavni sadržaj */}
      <div className="services">
        <h2>Brzi servis laptopa i računala</h2>
        <p>Većina popravaka ≤ 3 dana</p>

        <ul className="services-list">
          <li>Čišćenje od prašine</li>
          <li>Ubrzavanje rada</li>
          <li>Instalacija / Reinstalacija Windowsa</li>
          <li>Ugradnja novih dijelova</li>
          <li>Popravci kućišta</li>
          <li>Zamjena ekrana / tipkovnica</li>
          <li>Popravci matičnih ploča</li>
          <li>Popravci oštećenih portova</li>
          <li>Vađenje podataka sa neispravnog računala</li>
          <li>Dubinsko brisanje osjetljivih podataka</li>
        </ul>

        <p className="highlight">Besplatna brza dijagnostika</p>
        <p className="highlight">Mogućnost izdavanja R1 računa</p>
      </div>



      {/* Kontakt sekcija */}
      <div className="contact">
        <h3>Kontaktirajte nas (WhatsApp)</h3>
        <p>MOB: <a href="tel:+38598776911">+385 98 776 911</a></p>
        <p>&gt;Za popravke brodske, auto i kućne tehnike pošaljite upit&lt;</p>
      </div>

      {/* Slika */}
      <img src={yourImage} alt="IT Solutions" className="centered-image" />
    </div>
  );
};

export default AppWrap(ITSolutions,'itsolutions');

