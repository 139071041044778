import React, {useRef} from 'react'
import "./Kontakt.css";
import emailjs from '@emailjs/browser';
import { AppWrap} from '../../wrapper';



const Kontakt = () => {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ni2z8ys', 'template_1qfh9gh', form.current, 'R2_oNkQ-aZvpFlEEw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="join__app" id="join__us">
      <div className='join__left'>
      <hr />
        <div>
          <span className='stroke-text'>Mob :</span>
          <span style={{ color: 'var(--orange)' }}>099 45 88 007</span>


          
        </div>   
        
        <div>
          <span className='stroke-text'>KONTAKTIRAJTE</span>
          <span>NAS</span>
        </div>
        <div>
          <span></span>
          <span className='stroke-text'></span>
        </div>
      </div>
      <div className='join__right'>
         <form ref ={form} action='' className='email_container' onSubmit={sendEmail}>
          <input type="email" name="user_email" placeholder='Enter your email address'></input>
          <button className='btn btn__join'>Send</button>
         </form>      
      </div>
    
    
    
    </div>
  )
}

export default AppWrap(Kontakt, "kontakt");