import { images } from "../constants";
export const programsData = [
  {
    image: <img src={images.web} alt="web" className="program-image" />,
    heading: "Vanjsko Oglašavanje",
    details: (
      <div className="program-details">
        <p>
          Naša tvrtka specijalizirana je za vanjsko oglašavanje na strateški odabranim lokacijama diljem Hrvatske.
          Nudimo promociju na otvorenim površinama poput fasada, bilborda i vozila, osiguravajući da vaša poruka
          dođe do šire publike.
        </p>
        <p>
          Kod nas se promoviraju renomirane i velike tvrtke, što potvrđuje kvalitetu i učinkovitost naših usluga.
        </p>
        <h3>Prednosti vanjskog oglašavanja:</h3>
        <ul className="benefits-list">
          <li><strong>Visoka vidljivost:</strong> Vaš brend je prisutan na mjestima s velikom frekvencijom ljudi.</li>
          <li><strong>Stalna prisutnost:</strong> Poruka je dostupna 24/7, bez prekida.</li>
          <li><strong>Širok doseg:</strong> Doseg do različitih demografskih skupina.</li>
          <li><strong>Povećanje prepoznatljivosti:</strong> Stvaranje snažnog dojma kod potencijalnih kupaca.</li>
        </ul>
        <p>
          Obratite nam se i postanite dio uspješnih kampanja koje ostvaruju rezultate!
        </p>
      </div>
    ),
  },
  {
    image: <img src={images.visualChanges} alt="Promjene Vizuala" className="program-image" />,
    heading: "Promjene Vizuala",
    details: (
      <div className="program-details">
        <p>
          Naša usluga promjene vizuala uključuje skidanje starih vizuala i postavljanje novih na bilboarde,
          wallspace i megaboard panoima. Koristimo samo najkvalitetnije profile i materijale kako bismo osigurali
          vrhunsku kvalitetu i dugotrajnost vašeg oglašavanja.
        </p>
        <p>
          Za postavljanje koristimo nekoliko kombi vozila i košara za rad na visini, omogućujući nam efikasnu
          realizaciju projekata svih veličina.
        </p>
        <h3>Zašto odabrati nas za promjene vizuala:</h3>
        <ul className="benefits-list">
          <li><strong>Iskustvo:</strong> Dugogodišnje iskustvo u radu s različitim formatima vizuala.</li>
          <li><strong>Kvalitetna oprema:</strong> Pouzdana vozila i košare za rad na visini.</li>
          <li><strong>Brza realizacija:</strong> Završavamo projekte u najkraćem mogućem roku.</li>
        </ul>
      </div>
    ),
  },
  {
    image: <img src={images.emergencyRepairs} alt="Hitne Intervencije" className="program-image" />,
    heading: "Hitne Intervencije",
    details: (
      <div className="program-details">
        <p>
          Nudimo usluge hitnih intervencija na panoima i drugim oglašivačkim površinama, uključujući popravke
          oštećenja i zamjenu vizuala. Naš tim je dostupan za brze reakcije i rješavanje problema na licu mjesta.
        </p>
        <p>
          Specijalizirani smo za radove poput varenja na terenu, osiguravajući stabilnost i sigurnost vaše
          reklamne infrastrukture.
        </p>
        <h3>Prednosti naših hitnih intervencija:</h3>
        <ul className="benefits-list">
          <li><strong>Brza reakcija:</strong> Dostupnost za hitne popravke u najkraćem mogućem roku.</li>
          <li><strong>Profesionalna izvedba:</strong> Iskusni stručnjaci s modernom opremom.</li>
          <li><strong>Sigurnost:</strong> Garantiramo kvalitetu i stabilnost svih izvedenih radova.</li>
        </ul>
      </div>
    ),
  },
  
  {
    image: <img src={images.transfers} alt="Transferi Osoba" className="program-image" />,
    heading: "Transferi Osoba",
    details: (
      <div className="program-details">
        <p>
          Pružamo vrhunsku uslugu transfera osoba diljem Hrvatske, s naglaskom na udobnost, sigurnost i profesionalnost. 
          Naša moderna vozila i iskusni vozači osiguravaju vrhunsko iskustvo prijevoza.
        </p>
        <h3>Zašto odabrati nas?</h3>
        <ul className="benefits-list">
          <li>
            <strong>Nova Mercedes vozila:</strong> Prostrani kombiji s kapacitetom do osam putnika plus vozač,
            idealni za transfere do zračnih luka, turističke izlete i poslovna putovanja.
          </li>
          <li>
            <strong>Ekskluzivne limuzine:</strong> Luksuzna vozila za tri osobe plus vozač, savršena za privatne i poslovne transfere.
          </li>
          <li>
            <strong>Iskusni vozači:</strong> Naš tim čine profesionalci s višegodišnjim iskustvom, 
            pružajući siguran i ugodan prijevoz.
          </li>
          <li>
            <strong>Fleksibilnost:</strong> Prilagođavamo se vašim potrebama, bilo da se radi o pojedincima, obiteljima ili grupama.
          </li>
        </ul>
        <p>
          Naša usluga transfera osmišljena je kako bi ispunila sva vaša očekivanja. Kontaktirajte nas i doživite transfer na potpuno novoj razini!
        </p>
      </div>
    ),
  },
    {
      image: <img src={images.transport} alt="Transport Stvari" className="program-image" />,
      heading: "Transport Stvari",
      details: (
        <div className="program-details">
          <p>
            Nudimo pouzdan i siguran transport različitih vrsta tereta diljem Hrvatske. Naša moderna vozila i profesionalan pristup osiguravaju pravovremenu isporuku i zadovoljstvo klijenata.
          </p>
          <h3>Prednosti naše usluge:</h3>
          <ul className="benefits-list">
            <li>
              <strong>Pravovremenost:</strong> Garantiramo isporuku u dogovorenom roku, bez kašnjenja.
            </li>
            <li>
              <strong>Sigurnost:</strong> Vaše stvari su osigurane tijekom transporta, uz maksimalnu zaštitu.
            </li>
            <li>
              <strong>Fleksibilnost:</strong> Prilagođavamo se specifičnim zahtjevima i nudimo individualizirana rješenja za svaki teret.
            </li>
            <li>
              <strong>Profesionalan tim:</strong> Naši vozači i logistički tim osiguravaju besprijekoran tijek transporta.
            </li>
          </ul>
          <p>
            Naš cilj je omogućiti siguran, pouzdan i učinkovit prijevoz vaših stvari. Obratite nam se s povjerenjem i uvjerite se u kvalitetu naše usluge!
          </p>
        </div>
      ),
    }, 
    {
      image: <img src={images.excavator} alt="Iskopi Bagerom" className="program-image" />,
      heading: "Iskopi Bagerom od 5,5 Tona",
      details: (
        <div className="program-details">
          <p>
            Naša usluga iskopa bagerom od 5,5 tona pruža precizne i učinkovite građevinske radove. 
            Idealno za manje i srednje projekte, uz pouzdanu opremu i stručno osoblje, osiguravamo kvalitetu i točnost.
          </p>
          <h3>Što uključuje usluga?</h3>
          <ul className="benefits-list">
            <li>
              <strong>Različiti tipovi iskopa:</strong> Iskopi za temelje, bazene, kanale, septičke jame i druge građevinske projekte.
            </li>
            <li>
              <strong>Visoka preciznost:</strong> Naš bager omogućava precizne radove čak i na ograničenim prostorima.
            </li>
            <li>
              <strong>Prilagodljiva oprema:</strong> Uključuje standardnu kašiku, planirku i priključke za pikamer, što omogućava rad na raznovrsnim terenima.
            </li>
            <li>
              <strong>Iskusni operateri:</strong> Naš tim ima višegodišnje iskustvo u radu s bagerima i osigurava maksimalnu sigurnost i učinkovitost.
            </li>
            <li>
              <strong>Ekološki pristup:</strong> Radimo uz minimalan utjecaj na okolinu i osiguravamo zbrinjavanje iskopanog materijala na odgovarajući način.
            </li>
          </ul>
          <p>
            Naša usluga prilagođena je kako bi zadovoljila specifične potrebe vašeg projekta. 
            Kontaktirajte nas za dogovor i osigurajte kvalitetne iskope s najnovijom opremom i stručnim timom.
          </p>
        </div>
      ),
    },
    
];
