import logo from "./logo.svg";
import "./App.css";
import Hero from "./components/Hero/Hero";
import Offers from "./components/Offers/Offers";
import Reasons from "./components/Reasons/Reasons";
import Plans from "./components/Plans/Plans";
import Testimonials from "./components/Testimonials/Testimonials";
import Footer from "./components/Footer/Footer";
import Rental from "./container/Rental/Rental";
import ScrollToTop from "react-scroll-to-top";
import Partneri from "./container/Partneri/Partneri";
import Vizuali from "./container/Vizuali/Vizuali";
import Iskopi from "./container/Iskopi/Iskopi";
import Kontakt from "./components/Kontakt/Kontakt";

import ITSolutions from "./container/ITSolutions/ITSolutions";
import ITProdaja from "./container/ITSolutions/ITProdaja";
import Oglasavanje from "./container/Oglasavanje/Oglasavanje";

function App() {
  return (
    <div className="App">
      <Hero />
      <Offers />
      <Oglasavanje/>
      <Vizuali/>
      <Iskopi/>
      {/*<Reasons />*/}
      {/*<Plans />*/}
      {/*<Rental />*/}
      <ITSolutions />
      <ITProdaja/>
      <Partneri/>
      <Testimonials />
      <Kontakt />
      <Footer />
      <ScrollToTop className="my-custom-scroll-btn" smooth={true} />
    </div>
  );
}

export default App;
