import React from 'react'
import "./Reasons.css"
import {images} from "../../constants";
import { AppWrap} from '../../wrapper';


const Reasons = () => {
  return (
    <div className='Reasons' id= "reasons">
{/*left side-----------------------------*/}

      <div className='reasons__left'>
        <img src={images.image1} alt=""></img>
        <img src={images.image2} alt=""></img>
        <img src={images.image3} alt=""></img>
        <img src={images.image4} alt=""></img>
      </div>

{/*right side-----------------------------*/}

      <div className='reasons__right'>
        <span>some reason</span>
        <div>
          <span className='stroke-text'>why </span>
          <span>choose us?</span>
        </div>
        <div className='details'>
          <div>
            <img src={images.tick} alt=""/>
            <span>OVER 140+ EXPERT COACHS</span>
          </div>
          <div>
            <img src={images.tick} alt=""/>
            <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
          </div>
          <div>
            <img src={images.tick} alt=""/>
            <span>1 FREE PROGRAM FOR NEW MEMBER</span>
          </div>
          <div>
            <img src={images.tick} alt=""/>
            <span>RELIABLE PARTNERS</span>
          </div>
        </div>
        {/*our oartners-----------------------------*/}
        <div >
          <span className='ourPartners'>our partners</span>
          <div className='partners'>
            <img src={images.lidl} alt=""/>
            <img src={images.plodine} alt=""/>
            <img src={images.konzum} alt=""/>
            <img src={images.crosig} alt=""/>
            <img src={images.papirus} alt=""/>
            <img src={images.cistoca} alt=""/>
            <img src={images.crosig} alt=""/>
            <img src={images.pan} alt=""/>
            <img src={images.cistoca} alt=""/>          
            <img src={images.outdoor} alt=""/>         
            <img src={images.nigra} alt=""/> 
            <img src={images.yadar} alt=""/> 
          </div>
        </div>
      
      
      </div>
    
    </div>
  )
}

export default AppWrap(Reasons, "reasons")