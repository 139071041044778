import React, { useState } from 'react';
import './NavigationDots.css'; // Import CSS za stiliziranje

const NavigationDots = () => {
  const [active, setActive] = useState('');

  const handleMouseOver = (item) => {
    setActive(item);
  };

  const handleMouseOut = () => {
    setActive('');
  };

  return (
    <div className="app__navigation">
      {['početna', 'Oglasavanje', 'iskopi', 'itsolutions', 'kontakt'].map((item, index) => (
        <a
          href={`#${item}`}
          key={item + index}
          className="app__navigation-dot"
          onMouseOver={() => handleMouseOver(item)}
          onMouseOut={handleMouseOut}
        >
          {/* Tooltip text koji je vidljiv samo pri hoveru */}
          <span className={`tooltip ${active === item ? 'active' : ''}`}>{item}</span>
        </a>
      ))}
    </div>
  );
};

export default NavigationDots;
