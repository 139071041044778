import React from 'react'
import {BsTwitter, BsInstagram} from 'react-icons/bs'
import {FaFacebookF} from 'react-icons/fa'
import "./hoc.css"

const SocialMedia = () => {
  return (
    <div className='app__social'>
      {/* 
      <div className='social__links'>
        <FaFacebookF />     
      </div>
      <div className='social__links'>
        <BsInstagram />     
      </div>
      <div className='social__links'>
        <BsTwitter />     
      </div>
      */}
    </div>
  )
}

export default SocialMedia
