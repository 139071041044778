import React, {useState} from 'react'
import "./Testimonials.css"
import { testimonialsData } from '../../data/testimonialsData'
import {images}  from "../../constants"
import {motion} from "framer-motion"
import { AppWrap} from '../../wrapper';





const Testimonials = () => {
  const transition = {type:"spring", duration:3}
 
  const [selected, setSelected] = useState(0);
  const testimonialsLength = testimonialsData.length;

  return (
    <div className='testimonials__app' id="testimonials__app">
    {/*left side----------------- */}
    <div className='testimonials__left'>
      <span>iskustva korisnika</span>
      <span className='testimonials__header stroke-text'>Što naši</span>
      <span className='testimonials__header'>klijenti kažu</span>

      <motion.span
      key={selected}
      initial={{opacity:0, x:-100}}
      animate={{opacity:1, x:0}}
      exit={{opacity:1, x:-100}}
      transition={transition}
      >
        {testimonialsData[selected].review}
      </motion.span>
      <span>
        {testimonialsData[selected].name}{" - "}
        {testimonialsData[selected].status}
      </span>
      <span>
      </span>
    </div>
    {/*right side----------------- */}
      <div className='testimonials__right'>
      <motion.div
        initial={{opacity:0, x:-100}}
        whileInView={{opacity:1, x:0}}
        transition={{...transition, duration:2}}     
      ></motion.div>
      <motion.div
        initial={{opacity:0, x:100}}
        whileInView={{opacity:1, x:0}}
        transition={{...transition, duration:2}}
      ></motion.div>

        <motion.img
          key={selected}
          initial={{opacity:0, x:100}}
          animate={{opacity:1, x:0}}
          exit={{opacity:1, x:-100}}
          transition={transition}
        src={testimonialsData[selected].image} alt=""/>

        <div className='arrows'>
          <img 
            onClick={()=>{
            selected===0 
            ? setSelected(testimonialsLength-1)
            : setSelected((prev)=>prev - 1)}} 
            src={images.leftArrow} alt=""/>

          <img 
            onClick={()=>{
            selected===testimonialsLength -1 
            ? setSelected(0)
            : setSelected((prev)=>prev + 1)}} 
            src={images.rightArrow} alt=""/>
        
        </div>
      </div>
    </div>
  )
}

export default AppWrap(Testimonials, "testimonials")