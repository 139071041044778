import React, { useState, useEffect } from "react";
import './Rental.css';
import { AppWrap } from '../../wrapper';
import { createClient } from 'contentful';


const client = createClient({
  space: '7lkluz6ffmt3',
  accessToken: '2gkj1YKqTPvQ4bjZMcvbldW2RFiHiWpHTJW_Yw2YsUU',
});

const Rental = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.getEntries({
        content_type: 'rental',
      });
      setData(response.items);
    };
    fetchData();
  }, []);
  console.log(data)

  return (
    <div>
      <div className='Advertising' id='advertising'>
        <div className='Offers__header'>
          <span className='stroke-text'>Promjena</span>
          <span>Vizuala</span>
        </div>
      </div>

      <div className='Advertising__cards'>
      
      
      {data.map((item) => (
        <div className='Advertising__card' key={item.sys.id}>
          <div className='Advertising__card-image'>
            <img 
              src={item.fields.image.fields.file.url}
              alt={item.fields.image.fields.file.fileName}
            />  
          </div>
          <div className='Advertising__card-content'>
            <h3>{item.fields.name}</h3>
            <p>{item.fields.info}</p>          
            <p>price <span>{item.fields.price} €</span> / per day</p>          
            <p>damaged <span>{item.fields.damageCharged} €</span> / per item</p>          
          </div>
        </div>
      ))}
      </div>

    </div>
  );
};



export default AppWrap(Rental, 'Vizuali');
