import React, { useState, useEffect } from "react";
import "./Oglasavanje.css";
import { createClient } from "contentful";
import { AppWrap } from "../../wrapper";

const client = createClient({
  space: "7lkluz6ffmt3",
  accessToken: "2gkj1YKqTPvQ4bjZMcvbldW2RFiHiWpHTJW_Yw2YsUU",
});

const Oglasavanje = () => {
  const [data, setData] = useState([]);
  const [showAvailableOnly, setShowAvailableOnly] = useState(false); // Stanje za checkbox

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.getEntries({
        content_type: "city",
      });
      setData(response.items);
    };
    fetchData();
  }, []);

  // Filtrirane lokacije
  const filteredData = showAvailableOnly
    ? data.filter((item) => item.fields.raspolozivost)
    : data;

  return (
    <section id="Oglasavanje" className="oglasavanje">
      <header className="advertising-header">
        <div className="Oglasavanje__header">
          <span className="stroke-text">vanjsko</span>
          <span>oglašavanje</span>
        </div>
        <div className="Poruka">
          Pronađite najbolju lokaciju za svoje oglašavanje.
        </div>
      </header>

      {/* Checkbox za filtriranje */}
      <div className="filter-options">
        <label>
          <input
            type="checkbox"
            checked={showAvailableOnly}
            onChange={() => setShowAvailableOnly(!showAvailableOnly)}
            style={{ marginRight: "10px" }}
          />
          <span className="text">Prikaži samo raspoložive lokacije</span>
        </label>
      </div>

      <div className="advertising-cards">
        {filteredData.map((item) => (
          <div className="advertising-card" key={item.sys.id}>
            <div className="advertising-card-image">
              <img
                src={item.fields.image.fields.file.url}
                alt={item.fields.image.fields.file.fileName}
              />
            </div>
            <div className="advertising-card-content">
              <h2>{item.fields.grad}</h2>
              <p>{item.fields.info}</p>
              <p>
                <strong>Reklamne Površine:</strong> {item.fields.kindOf}
              </p>
              <p>
                <strong>Dimenzije:</strong> {item.fields.dimensions}
              </p>
              <p>
                <strong>Osvjetljenje:</strong>{" "}
                {item.fields.lighting ? "Da" : "Ne"}
              </p>
              <p>
                <strong>Raspoloživost:</strong>{" "}
                {item.fields.raspolozivost ? "Raspoloživo" : "Nije raspoloživo"}
              </p>
              {item.fields.locations && (
                <div className="advertising-card-locations">
                  <h3>Lokacija:</h3>
                  {item.fields.locations.map((location, index) => (
                    <p key={index}>
                      Lat: {location.lat}, Lng: {location.lon}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AppWrap(Oglasavanje, "Oglasavanje");
