import React, { useState } from 'react'
import "./Offers.css"
import { images } from "../../constants"
import { programsData } from "../../data/programsData"
import { AppWrap } from '../../wrapper';

const Offers = () => {
  // Držimo stanje za koji program je otvoren
  const [activeIndex, setActiveIndex] = useState(null);

  // Funkcija za promjenu stanja kad kliknemo na naslove
  const toggleDetails = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);  // Ako je već otvoren, zatvori ga
    } else {
      setActiveIndex(index);  // Otvori novi program
    }
  };

  return (
    <div className='Offers' id="offers">
      {/*Offers Header */}
      <div className='Offers__header'>
        <span className='stroke-text'>istražite</span>
        <span>naše</span>
        <span className='stroke-text'>ponude</span>
      </div>
      
      <div className='offers__programs'>
        {programsData.map((program, index) => (
          <div className='offers' key={index}>
            {/* Naslov sa klikabilnom ikonom */}
            <div className='offers__heading' onClick={() => toggleDetails(index)}>
              <span>{program.heading}</span>
              <span className='toggle-icon'>
                {activeIndex === index ? '   -' : '   +'}  {/* Plus ili minus ovisno o stanju */}
              </span>
            </div>

            {/* Detalji koji su vidljivi samo ako je odabrani program aktivan */}
            {activeIndex === index && (
              <div className='offers__details'>
                <span>{program.details}</span>
                {/*
                <div className='join_now'>
                  <span>Info</span>
                  <img src={images.rightArrow} alt="" />
                </div>
                */}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default AppWrap(Offers, 'info');
