import React from 'react';
import "./Footer.css";
import {images} from "../../constants"

const Footer = () => {
  return (
    <div className='footer__app'>
      <hr/>
      <div className='footer__container'>
        <div className='social__media'>
          <img src={images.github} alt = "..."/>
          <img src={images.instagram} alt = "..."/>
          <img src={images.linkedin} alt = "..."/>
        </div>
        <div className="footer__logo">
          <img src={images.red_logo} alt = "..."/>      
        </div>
      </div>
      <div className='blur blur-left'></div>
      <div className='blur blur-right'></div>
    </div>    
    
    
    
  
  )
}

export default Footer