import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import { images } from "../../constants";
import { motion } from "framer-motion";
import "./Hero.css";
import { AppWrap } from '../../wrapper';

const Hero = () => {
  const [currentImage, setCurrentImage] = useState(images.b2); // Početna slika b2

  useEffect(() => {
    const imageChangeInterval = setInterval(() => {
      setCurrentImage((prevImage) => {
        if (prevImage === images.b2) {
          return images.b3; // Prijelaz iz b2 u b3
        } else if (prevImage === images.b3) {
          return images.b4; // Prijelaz iz b3 u b4
        } else {
          return images.b2; // Prijelaz iz b4 u b2
        }
      });
    }, 4000); // 3000ms = 3 sekunde (vrijeme za svaku sliku)

    // Čisti interval kad komponenta bude uklonjena
    return () => clearInterval(imageChangeInterval);
  }, []);

  const transition = { type: "spring", duration: 3 };

  return (
    <div className="proba">
      <div className="blur hero-blur"></div>
      
      {/* Left side */}
      <div className="hero__left">
        <Header />
        
        {/* The best */}
        <div className="thebest">
          <motion.div
            initial={{ left: "270px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>22 RED - pouzdan partner u poslovanju</span>
        </div>

        {/* Counters */}
        <div className="brojaci">
          <div>
            <span>+122</span>
            <span>klijenata</span>
          </div>
          <div>
            <span>96%</span>
            <span>povrat klijenata</span>
          </div>
          <div>
            <span>+49</span>
            <span>artikala</span>
          </div>
        </div>

        {/* Hero Buttons */}
        <div className="hero-buttons">
          <button className="btn" onClick={() => window.location.href = 'tel:+385994588007'}>
            Call
          </button>

          <button className="btn" onClick={() => window.location.href = 'https://wa.me/385994588007'}>
            WhatsApp
          </button>
        </div>
      </div>

      {/* Right side */}
      <div className="hero__right">
        <motion.img
          src={currentImage} 
          alt="hero_image"
          className="hero_image"
          key={currentImage} // Ključ za bolji prijelaz
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }} // Tranzicija između slika
        />
        <motion.img
          initial={{ right: "11rem" }}
          whileInView={{ right: "26rem" }}
          transition={transition}
          src={images.hero_image_back}
          alt="hero_image_back"
          className="hero_image_back"
        />
      </div>
    </div>
  );
};

export default AppWrap(Hero, "početna");
